import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import TreatmentReportTemplate from '@/Interfaces/TreatmentReportTemplate';
import { Prop } from 'vue-property-decorator';
import TreatmentReportTemplateForm from '@/views/TemplateIndex/Components/TreatmentReportTemplateIndex/Forms/TreatmentReportTemplateForm.vue';

@Component({
    components: {
        OverviewTable,
        TreatmentReportTemplateForm,
    },
})
export default class TreatmentReportTemplateIndex extends IndexView<TreatmentReportTemplate> {
    @Prop({ default: null }) endpoint!: string|null;
    @Prop({ default: null }) endpointEdit!: string|null;
    protected selectedSupportedCountryId: number | null = null;

    protected refresh() {
        this.$refs.overviewTable.refresh();
    }
}
