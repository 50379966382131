import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import TreatmentReportInterface from "@/Interfaces/TreatmentReportInterface";

@Component({
    name: 'MedicalQuestionnaire',
    components: {},
})
export default class MedicalQuestionnaire extends Vue {
    @Prop() value!: any;
    @Prop() data!: TreatmentReportInterface;
    @Prop() therapeuticGoals!: any;

    public formData = {
        question_1: null,
        question_2: null,
        goal_id: null,
        substantiation: null
    }

    mounted(){
        this.data.formData.medical_grounds = !!this.conclusion;
    }
    @Watch('value', {immediate: true})
    onValueChange(){
        this.formData = this.value;
    }

    get conclusion(): boolean | null {
        if (!this.formFilled) return null;

        return (!!this.formData.question_1 || !!this.formData.question_2);
    }

    get formFilled(): boolean {
        return this.formData.question_1 !== null || this.formData.question_2 !== null
    }

    @Watch('formData', {deep: true})
    onSelectedChange() {
        Vue.set(this.data, 'medical_grounds_invalid' , false);

        if(this.formData.question_1 === false && this.formData.question_2 === false){
            this.formData.goal_id = null;
        }

        if((this.formData.question_1 === true || this.formData.question_2 === true) && this.formData.goal_id === null){
            this.formData.goal_id = this.therapeuticGoals.length > 0 ? this.therapeuticGoals[0].id : null;
        }
        this.$emit('input', this.formData);
    }

    @Watch('conclusion')
    onConclusionChange(){
            this.data.formData.medical_grounds = !!this.conclusion;
           if(this.conclusion === false) this.formData.goal_id = null;
    }

    get hintCharactersLeft(): number {
        const substantiation = this.formData.substantiation ?? "";

        return 256 - substantiation.length;
    }
}
