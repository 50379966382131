import Component from "vue-class-component";
import PopupForm from "@/partials/PopupForm";
import {Prop} from "vue-property-decorator";
import popup from "@/components/popup/popup.vue";
import QualificationFormData from "@/Interfaces/QualificationsFormData";

@Component({
    components: {
        popup
    },
})
export default class QualificationForm extends PopupForm<QualificationFormData> {
    @Prop({ default: 0 }) supportedCountryId!: number;
    protected modelName = 'Qualification';
    protected endpoint = 'qualifications';

    afterToggle(): void {
        this.formData.supported_country_id = this.supportedCountryId;
    }
}