import apiClient from '@/apiClient';
import { Commit } from 'vuex';

declare interface MeState {
    detailsNeeded: boolean;
    permissions: Array<string>;
    roles: Array<string>;
}

const initialState = {
    id: null,
    name: null,
    email: null,
    first_name: null,
    last_name: null,
    phone_number: null,
    detailsNeeded: true,
    permissions: [],
};

const mutations = {
    set(state: MeState, payload: Record<any, any>) {
        Object.assign(state, payload);
        state.detailsNeeded = false;
    },
    unset(state: MeState) {
        Object.assign(state, initialState);
        state.detailsNeeded = true;
    },
};

const getters = {
    hasPermission(state: MeState) {
        return (permission: string) => state.permissions.includes(permission);
    },
    get(state: MeState){
        return state;
    }
};

const actions = {
    fetch(context: { commit: Commit; state: MeState }) {
        apiClient.get('me').then((response) => {
            context.commit('set', response.data);
        });
    },
};

export default {
    namespaced: true,
    getters,
    state: initialState,
    mutations,
    actions,
};
