import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import nl from 'vuetify/src/locale/nl';
import en from 'vuetify/src/locale/en';
import fr from 'vuetify/src/locale/fr';
import de from 'vuetify/src/locale/de';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { nl, fr, en, de },
        current: localStorage.getItem('locale') ?? 'nl',
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#049fcb',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                background: '#f4f8f9',
            },
        },
    },
});
