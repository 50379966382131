const state = {
    locationId: null,
    roomId: null,
    date_from: null,
    data: [],
};

const mutations = {
    setData(state: { data: any }, data: any) {
        state.data = data;
    },
    setRoomId(state: { roomId: number }, data: number) {
        state.roomId = data;
    },
    setLocationId(state: { locationId: any }, data: number) {
        state.locationId = data;
    },
    setDateFrom(state: { date_from: any }, data: any) {
        state.date_from = data;
    },
};

const getters = {
    data(state: { data: any }) {
        return state.data;
    },
    roomId(state: { roomId: any }) {
        return state.roomId;
    },
    date_from(state: { date_from: any }) {
        return state.date_from;
    },
    locationId(state: { locationId: any }) {
        return state.locationId;
    },
};

export default {
    namespaced: true,
    getters,
    state,
    mutations,
};
