import Vue from 'vue';
import Component from 'vue-class-component';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import TreatmentReport from '@/components/TreatmentReport/TreatmentReport.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import { Ref } from 'vue-property-decorator';
import { default as TreatmentReportComponent } from '@/components/TreatmentReport/TreatmentReport';
import PaymentScreen from '@/components/PaymentScreen/PaymentScreen.vue';
import { default as PaymentScreenComponent } from '@/components/PaymentScreen/PaymentScreen';

@Component({
    name: 'TreatmentReportPopup',
    components: {
        Popup,
        TreatmentReport,
        MenuButton,
        PaymentScreen,
    },
})
export default class TreatmentReportPopup extends Vue {
    private loading = false;
    private canEdit = this.$store.getters[ 'me/hasPermission' ]('treatment-report.update');

    @Ref() treatmentReportPopup!: PopupComponent;
    @Ref() paymentPopup!: PopupComponent;
    @Ref() paymentScreen!: PaymentScreenComponent;
    @Ref() treatmentReport!: TreatmentReportComponent;

    primaryClick(data: any) {
        this.$emit('primaryClick', data);
    }

    private closePaymentPopup() {
        this.paymentPopup.toggle();
    }

    public close() {
        const elem = document.querySelector('html') as HTMLElement;
        elem.style.overflow = 'auto';
    }

    public toggle(data = {}) {
        this.treatmentReportPopup.toggle(data);

        const elem = document.querySelector('html') as HTMLElement;
        elem.style.overflow = 'hidden';
    }

    save(type: string) {
        this.loading = true;

        this.treatmentReport.saveOrUpdate().then((response) => {
            this.loading = false;
            this.treatmentReportPopup.toggle();
            this.treatmentReport.convertAppointmentPayment(response.data.appointment.id).then(() => this.$emit('saved', response));

            if ( type === 'pay' ) {
                this.close();
                this.paymentPopup.toggle();
            }
        }).catch(() => {
            this.loading = false;
        });
    }
}
