import Vue from 'vue';
import Vuex from 'vuex';
import device from '@/store/modules/device';
import application from '@/store/modules/application';
import authentication from '@/store/modules/authentication';
import country from '@/store/modules/country';
import me from '@/store/modules/me';
import mru from '@/store/modules/mru';
import mutationtypes from '@/store/modules/mutationtypes';
import locations from '@/store/modules/locations';
import locales from '@/store/modules/locales';
import paymentmethods from '@/store/modules/paymentmethods';
import calendarplanning from '@/store/modules/calendarplanning';
import reporthistory from '@/store/modules/reporthistory';

Vue.use(Vuex);

export default new Vuex.Store({
    // Convention is to have one module for each set of data that is shared between components
    modules: {
        application,
        authentication,
        country,
        me,
        device,
        locales,
        locations,
        mru,
        mutationtypes,
        paymentmethods,
        calendarplanning,
        reporthistory,
    },
});
