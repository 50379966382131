import Vue from 'vue';
import Component from 'vue-class-component';
import apiClient from '@/apiClient';
import { namespace } from 'vuex-class';
import Avatar from '@/components/Avatar/Avatar.vue';
import { Prop, Watch } from 'vue-property-decorator';
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue';

@Component({
    components: {
        Avatar,
        LanguagePicker
    },
})
export default class UserMenu extends Vue {
    @namespace('authentication').State loginNeeded: any;
    @namespace('authentication').Action askForLogin: any;
    @namespace('me').Mutation unset: any;
    @namespace('me').State name: any;

    @Prop({ default: false }) active?: boolean;

    private menuToggle: boolean = false;

    @Watch('active')
    onActiveChange() {
        if ( !this.active ) {
            this.menuToggle = false;
        }
    }

    logout() {
        apiClient.post('/logout').then(() => {
            this.unset();
            this.askForLogin();
        });
    }
}
