import Vue from 'vue';
import Component from 'vue-class-component';
import { RouteConfig } from 'vue-router';
import { namespace } from 'vuex-class';
import UserMenu from '@/components/UserMenu/UserMenu.vue';
import { Ref, Watch } from 'vue-property-decorator';

@Component({
    components: { UserMenu },
})
export default class Navigation extends Vue {
    public overlayVisible = false;
    public isMenuOpen = false;
    public currentLocale = this.$i18n.locale;
    private keepMenuOpenInterval = null as any;

    @namespace('me').State permissions: any;
    @namespace('me').State detailsNeeded: any;

    @Ref('main-navigation') public readonly mainNavigation!: any;

    mounted() {
        document.addEventListener('mouseout', (e) => {
            if ( !e.relatedTarget ) {
                this.overlayVisible = false;
            }
        });
    }

    @Watch('overlayVisible')
    onOverlayVisibleChange() {
        if ( this.overlayVisible ) {
            this.keepMenuOpenInterval = setInterval(() => {
                this.mainNavigation.$data.isMouseover = true;
            }, 0);
        } else {
            clearInterval(this.keepMenuOpenInterval);
            this.mainNavigation.$data.isMouseover = false;
        }
    }

    goToDashboard() {
        if ( this.$route.name === 'Dashboard' ) return;

        this.$router.push({
            name: 'Dashboard',
        });
    }

    get accessibleRoutes() {
        return this.$router.options.routes?.filter((route: RouteConfig) => {
            return route.meta?.showInNavigation !== false && (
                (route.meta && !('permission' in route.meta)) || this.permissions.includes(route.meta?.permission)
            );
        });
    }

    accessibleChildRoutes(parent: RouteConfig) {
        return parent.children?.filter((route: RouteConfig) => {
            return (route.meta && !('permission' in route?.meta)) || this.permissions.includes(route.meta?.permission);
        });
    }

    hideOverlay() {
        this.overlayVisible = false;
    }

    showOverlay() {
        this.overlayVisible = true;
    }
}
