import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Vue from 'vue';
import Language from '@/Interfaces/Language';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import apiClient from '@/apiClient';
import { namespace } from 'vuex-class';

@Component({
    name: 'TranslatableTextarea',
    components: {
        Wysiwyg,
    },
})
export default class TranslatableTextarea extends Vue {
    @Prop() value!: any;

    @namespace('mru').State('primarySupportedCountryId') primarySupportedCountryId!: any;
    protected languages = [] as Array<Language>;
    protected languageLoaded = false;
    protected tab = 0;
    protected input = '' as string;

    private getLanguages() {
        apiClient.get(`supported-countries/dto/${this.primarySupportedCountryId}`).then((response) => {
            this.languages = response.data.locales.map((locale: any) => {
                return {
                    id: locale.id,
                    name: locale.language.name,
                    code: locale.code,
                };
            });

            this.tab = this.languages.findIndex((item) => {
                return item.code === this.$i18n.locale;
            });

            this.languageLoaded = true;
            this.$forceUpdate();
        });
    }

    mounted(): void {
        this.getLanguages();
    }
}
