import { Prop, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
    name: 'TranslatableTextField'
})
export default class TranslatableTextField extends Vue {
    @Prop({ required: true }) target!: string;
    @Prop() value!: any;

    isOfPrimaryLocale(locale: string): boolean {
        return i18n.locale === locale || this.getCountLocaleFields() === 1;
    }

    @Watch('value', { deep: true })
    onValueChange() {

        if(!Object.keys(this.value).includes('translation')) return;

        if ( Object.keys(this.value.translation[ this.target ]).includes(i18n.locale) ) {
            const intermediate = this.value;
            intermediate.translation[ this.target ][ i18n.locale ] = this.value[ this.target ];
            this.$emit('input', intermediate);
        } else if ( Object.keys(this.value.translation[ this.target ]).length === 1 ) {
            const intermediate = this.value;
            intermediate.translation[ this.target ][ Object.keys(this.value.translation[ this.target ])[ 0 ] ] = this.value[ this.target ];
            this.$emit('input', intermediate);
        }
    }

    get localeFields(): Array<any> | null {
        return 'translation' in this.value ? this.value.translation[ this.target ] : null;
    }

    getCountLocaleFields(): number {
        return 'translation' in this.value ? Object.keys(this.value.translation[ this.target ]).length : 0;
    }

    get primaryLocaleString(): string {
        return this.getCountLocaleFields() === 1 ? '' : this.$i18n.locale.toUpperCase();
    }
}
