import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import PopupForm from '@/partials/PopupForm';
import DateField from '@/components/DateField/DateField.vue';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import AgreementUpload from '@/views/ClientIndex/Forms/AgreementUpload/AgreementUpload.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput/PhoneNumberInput.vue';
import { namespace } from 'vuex-class';
import Location from '@/Interfaces/Location';
import Brand from '@/Interfaces/Brand';
import Role from '@/Interfaces/Role';
import apiClient from '@/apiClient';
import EmployeeFormData from '@/Interfaces/EmployeeFormData';

@Component({
    name: 'DesignSystem',
    components: {
        Modal,
        DateField,
        DeactivateToggle,
        AgreementUpload,
        PhoneNumberInput,
    },
})
export default class DesignSystem extends PopupForm<EmployeeFormData> {
    @namespace('me').State id!: any;

    protected modelName = 'Employee';
    protected endpoint = 'employees';

    public locations: Array<Location> = [];
    public brands: Array<Brand> = [];
    public roles: Array<Role> = [];

    public weekDays = [ ...Array(7).keys() ].map(day => {
        return this.$dayjs().startOf('week').add(day, 'day').format('dd');
    });

    public dataLoading = {
        brands: false,
        locations: false,
        roles: false,
    };

    public afterToggle(): void {
        this.formData.brandIds = this.formData.brandIds || this.brands.map(b => b.id as number);

        this.getBrands();
        this.getLocations();
        this.getRoles();
    }

    public get practitioner(): boolean {
        return !!this.roles.find(role => this.formData.roleIds?.find(id => id === role.id) && role.practitioner);
    }

    public get self(): boolean {
        return this.formData.id === this.id;
    }

    /**
     * Get all brands (laser devices)
     */
    private getBrands(): void {
        this.brands = [];

        apiClient.get('brands')
            .then(response => this.brands = response.data)
            .finally(() => this.dataLoading.brands = false);

        this.dataLoading.brands = true;
    }

    /**
     * Get all locations
     */
    private getLocations(): void {
        this.locations = [];

        apiClient.get('locations/raw')
            .then(response => this.locations = response.data)
            .finally(() => this.dataLoading.locations = false);

        this.dataLoading.locations = true;
    }

    /**
     * Get all roles
     */
    private getRoles(): void {
        this.roles = [];

        apiClient.get('roles')
            .then(response => this.roles = response.data)
            .finally(() => this.dataLoading.roles = false);

        this.dataLoading.roles = true;
    }
}
