import currency from 'currency.js';
import i18n from '@/i18n';
import dayjs from 'dayjs';

// Functions to map fields provided by API to the way they are shown in table cells.
export default {
    get(key: string, value: any, type: string): string {
        const presenter = (item: any) => (type || key) in this ? this[ (type || key) ](item) : item;

        return (typeof value === 'object' && value) ? (value || []).map(presenter).join(', ') : presenter(value);
    },

    longdate: (value: string): string => {
        return value ? (new Date(value)).toLocaleDateString(i18n.locale, {
            weekday: 'short',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        }) : '';
    },

    shortdatetime: (value: string): string =>
        dayjs(value).format('DD-MM-YYYY HH:mm'),

    shortdate: (value: string): string =>
        value ? dayjs(value).format('DD-MM-YYYY') : '',

    timedate: (value: string): string =>
        value ? dayjs(value).format('HH:mm DD-MM-YYYY') : '',

    time_range: (value: string): string => {
        return value.split(' - ').map((time: string) => {
            return time ? new Date(time).toLocaleTimeString(i18n.locale, {
                hour: '2-digit',
                minute: '2-digit',
            }) : '00:00';
        }).join(' - ');
    },

    appointment_type_name: (value: string) => i18n.t(`CRM.Views.TemplateIndex.TreatmentReportTemplateIndex.AppointmentType.${value}`),

    birthdate: (value: string): string =>
        dayjs(value).format('D-M-YYYY'),

    boolean: (value: boolean): string =>
        value ? '✓' : '',

    booleanString: function(value: boolean): string {
        return value ? i18n.t('Global.Boolean.True').toString() : i18n.t('Global.Boolean.False').toString();
    },

    country: (value: string): string => i18n.t(`Global.Countries.${value}`).toString(),

    currency: (value: number): string =>
        value !== 0 ? currency(value, { symbol: '€', decimal: ',', separator: '.', precision: 2, fromCents: true }).format() : '-',

    discountcurrency: (value: number | string): string => {
        if(!value) return '-';

        if(typeof value === 'number') {
           return currency(value, { symbol: '€', decimal: ',', separator: '.', precision: 2, fromCents: true }).format();
        }

        return value.toString();
    },

    number: (value: number): number => value,

    role: (value: string) =>
        i18n.t(`Global.Api.Roles.${value}`),

    saleType: (value: string) => i18n.t(`Api.DaySummary.ProductTypes.${value}`),

    duration: (value: number) =>
        i18n.t('Global.Fields.Minutes', { minutes: value }),

    time: (value: string) => {
        const date = new Date(value);

        return [
            date.getHours().toString().padStart(2, '0'),
            date.getMinutes().toString().padStart(2, '0'),
        ].join(':');
    },

    vat: (value: number) =>
        i18n.t([ 'Global.Fields.Vat', value ].join('.')),
} as { [ key: string ]: any };
