import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';
import TreatmentReportTemplateIndex from '@/views/TemplateIndex/Components/TreatmentReportTemplateIndex/TreatmentReportTemplateIndex.vue';
import { Watch } from 'vue-property-decorator';
import IndexView from '@/partials/IndexView';
import TreatmentReportTemplate from '@/Interfaces/TreatmentReportTemplate';
import TreatmentReportTemplateForm from '@/views/TemplateIndex/Components/TreatmentReportTemplateIndex/Forms/TreatmentReportTemplateForm.vue';
import AgreementTemplateIndex from '@/views/TemplateIndex/Components/AgreementTemplateIndex/AgreementTemplateIndex.vue';
import AgreementTemplateForm from '@/views/TemplateIndex/Components/AgreementTemplateIndex/Forms/AgreementTemplateForm.vue';

@Component({
    components: {
        AgreementTemplateIndex,
        AgreementTemplateForm,
        SupportedCountries,
        TreatmentReportTemplateIndex,
        TreatmentReportTemplateForm,
    },
})
export default class TemplateIndex extends IndexView<TreatmentReportTemplate> {
    @namespace('me').State permissions: any;
    protected selectedSupportedCountryId: number | null = null;
    protected endpoints = {
        treatmentReport: {
            endpoint: '',
            endpointEdit: 'treatment-report-templates',
        },
        agreement: {
            endpoint: '',
            endpointEdit: 'template-agreements',
        }
    }
    public tab = 0 as number;

    private setTab(tab: number) {
        this.tab = tab;
    }

    private updateEndpoints(): void {
        this.endpoints.treatmentReport.endpoint = `treatment-report-templates/country/${this.selectedSupportedCountryId}`;
        this.endpoints.treatmentReport.endpointEdit = 'treatment-report-templates';

        this.endpoints.agreement.endpoint = `template-agreements/country/${this.selectedSupportedCountryId}`;
        this.endpoints.agreement.endpointEdit = 'template-agreements';
    }

    @Watch('selectedSupportedCountryId')
    onSelectedSupportedCountryIdChange() {
        if ( this.selectedSupportedCountryId ) {
            this.updateEndpoints();
        }
    }

    private changeTab(id: number) {
        const pathParts = this.$router.currentRoute.path.split('/');

        if ( pathParts.length > 3 ) {
            pathParts.pop();
        }

        const path = pathParts.join('/');
        this.$router.push([ path, id ].join('/'));
    }
}
