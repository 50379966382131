import SupportedCountry from '@/Interfaces/SupportedCountry';
import dayjs from 'dayjs';

declare interface MruUser {
    id: number;
    deactivated_at: string | null;
    locationNames?: Array<string>;
    name: string;
    roleNames?: Array<string>;
}

const state = {
    client: null,
    user: null as MruUser | null,
    date: dayjs().utc().startOf('day'),
    calendarView: 'day',
    location: null,
    scheduleLocationId: null,
    scheduleDate: null,
    scheduleWeek: null,
    scheduleTab: null,
    daySummaryLocationId: null,
    daySummaryDate: null,
    paymentScreenLocationId: null,
    paymentScreenEmployeeId: null,
    selectedAgreement: null,

    supportedCountries: [] as Array<SupportedCountry>,
    primarySupportedCountryId: null as null | number,
    overviewTableRequestCancelToken: null as any,
};

const mutations = {
    set(state: any, payload: Record<any, any>) {
        if (payload.value === undefined) return;
        state[ payload.key ] = payload.value;
    },
    setMruUserFromMe(state: any, payload: Record<any, any>) {
        state.user = payload;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
