import Vue from 'vue';
import Component from 'vue-class-component';
import GenderIndex from '@/views/DatabaseModels/Components/Gender/GenderIndex.vue';
import TreatmentCategoriesIndex from '@/views/DatabaseModels/Components/TreatmentCategories/TreatmentCategoriesIndex.vue';
import SupportedCountryIndex from '@/views/DatabaseModels/Components/SupportedCountries/SupportedCountryIndex.vue';
import CountryIndex from '@/views/DatabaseModels/Components/Countries/CountryIndex.vue';
import DeviceTypeIndex from '@/views/DatabaseModels/Components/DeviceTypes/DeviceTypeIndex.vue';
import PaymentMethodIndex from '@/views/DatabaseModels/Components/PaymentMethod/PaymentMethodIndex.vue';
import LocaleIndex from '@/views/DatabaseModels/Components/Locale/LocaleIndex.vue';
import RoleIndex from '@/views/DatabaseModels/Components/Roles/RoleIndex.vue';
import LanguageIndex from '@/views/DatabaseModels/Components/Languages/LanguageIndex.vue';
import TreatmentComplicationIndex from '@/views/DatabaseModels/Components/TreatmentComplications/TreatmentComplicationIndex.vue';
import TreatmentSideEffectsIndex from '@/views/DatabaseModels/Components/TreatmentSideEffects/TreatmentSideEffectsIndex.vue';

@Component({
    components: {
        GenderIndex,
        TreatmentCategoriesIndex,
        SupportedCountryIndex,
        CountryIndex,
        DeviceTypeIndex,
        PaymentMethodIndex,
        LocaleIndex,
        LanguageIndex,
        RoleIndex,
        TreatmentComplicationIndex,
        TreatmentSideEffectsIndex
    },
})
export default class DatabaseModels extends Vue {
    public tab: number | null = null;
}
