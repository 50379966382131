import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import TreatmentPicker from '@/components/TreatmentPicker/TreatmentPicker.vue';
import NumberField from '@/components/NumberField/NumberField.vue';
import {StrRandom} from '@/utils/utils';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import TreatmentReportPhotos
    from '@/components/TreatmentReport/Components/TreatmentReportPhotos/TreatmentReportPhotos.vue';
import TreatmentReportInjectable
    from '@/components/TreatmentReport/Components/TreatmentReportTreatment/TreatmentReportInjectable/TreatmentReportInjectable.vue';
import {namespace} from 'vuex-class';
import {ReportHistoryInterface} from '@/store/modules/reporthistory.ts';
import FloatField from '@/components/FloatField/FloatField.vue';
import DateField from '@/components/DateField/DateField.vue';
import Injectable from '@/Interfaces/Injectable';
import apiClient from '@/apiClient';
import i18n from '@/i18n';
import store from '@/store';
import MedicalQuestionnaire
    from "@/components/TreatmentReport/Components/TreatmentReportTreatment/MedicalQuestionnaire/MedicalQuestionnaire.vue";

@Component({
    name: 'TreatmentReportTreatment',
    components: {
        TreatmentPicker,
        NumberField,
        Wysiwyg,
        TreatmentReportPhotos,
        FloatField,
        DateField,
        TreatmentReportInjectable,
        MedicalQuestionnaire
    },
})
export default class TreatmentReportTreatment extends Vue {
    @Prop() data!: TreatmentReportInterface;
    @namespace('reporthistory').Mutation('set') public setReportHistory: any;
    @namespace('reporthistory').Getter('state') public history: any;

    protected formData = this.data.formData;
    protected canEdit = this.$store.getters['me/hasPermission']('treatment-report.update');
    protected therapeuticGoals: Array<{id: number; goal: string}> = [];

    private treatmentData: Array<any> = [];
    private showComplications: boolean = this.data.treatment.categoryName === 'Arts';
    private loadingTemplate: boolean = false;
    private reportHistoryObject: ReportHistoryInterface | null = null;

    @Watch('formData.treatment', {deep: true, immediate: true})
    private getTherapeuticGoals(): any {
        apiClient.get(`list/treatments/${this.formData.treatment}/therapeutic-goals`).then(res => {
            this.therapeuticGoals = res.data;
        })
    }

    private getCurrentPhotos(): Array<ReportHistoryInterface> {
        return this.data.photos.map((photo: any) => {
            return {
                appointment: {
                    datetime_from: this.data.appointment.datetime_from,
                    datetime_till: this.data.appointment.datetime_till,
                },
                practitioner: {
                    name: this.data.practitioner.name,
                    id: this.data.practitioner.id,
                },
                treatment: {
                    categoryName: this.data.treatment.categoryName,
                    name: this.data.treatment.name,
                },
                photos: [{
                    name: photo.name,
                    id: photo.id,
                    thumbnailId: photo.thumbnailId,
                }],
                id: this.data.id,
            };
        });
    }

    mounted() {
        this.setReportHistory({data: this.getCurrentPhotos()});

        const sideEffectDefault = this.data.side_effects[0];
        const complicationDefault = this.data.complications[0];

        if (!this.data.formData.side_effects.length) {
            if (!this.data.formData.side_effects.find(sideEffect => sideEffect === 1) && sideEffectDefault) {
                this.data.formData.side_effects.push(sideEffectDefault.id);
            }
        }
        if (!this.data.formData.complications.length) {
            if (!this.data.formData.complications.find(complication => complication === 1) && complicationDefault) {
                this.data.formData.complications.push(complicationDefault.id);
            }
        }

        this.formData.treatment_category_id = this.data.formData.treatment_category_id;

        this.formData.pre_save_photos = [];
        //this.getTherapeuticGoals();
        this.assignReportHistoryObject();
    }

    private loadedTreatmentData(data: any): void {
        this.treatmentData.push(data);
    }

    private removeNoneValueFromComplicationsOrSideEffects(formDataRef: string, input: Array<number>, previousInput: Array<number>): void {
        const dataObj = this.data as any;
        const removeItem = Object.values(dataObj[formDataRef])[0] as Record<string, any>;

        if (!previousInput.includes(removeItem.id) && input.includes(removeItem.id)) {
            Vue.set(this.formData, formDataRef, [removeItem.id]);
        } else if (this.formData[formDataRef]?.length > 1 && this.formData[formDataRef]?.includes(removeItem.id)) {
            Vue.set(this.formData, formDataRef, input.filter((x: number) => x !== removeItem.id));
        }
    }

    @Watch('formData.complications')
    complicationsChange(newVal: Array<number>, oldVal: Array<number>) {
        this.removeNoneValueFromComplicationsOrSideEffects('complications', newVal, oldVal);
    }

    @Watch('formData.side_effects')
    sideEffectChange(newVal: Array<number>, oldVal: Array<number>) {
        this.removeNoneValueFromComplicationsOrSideEffects('side_effects', newVal, oldVal);
    }

    @Watch('formData.treatment')
    treatmentWatcher(treatmentId: number): void {
        const isDoctorTreatment = this.treatmentData[0].Arts.find((arts: any) => arts.id === treatmentId);

        this.resetQuestionnaire();

        this.showComplications = isDoctorTreatment !== undefined;
    }

    public resetQuestionnaire(): void {
        this.formData.medical_grounds_questionnaire = {
            question_1: null,
            question_2: null,
            goal_id: null,
            substantiation: null
        }
    }

    @Watch('formData.therapeutic_goal_questionnaire', {deep: true})
    onQuestionnaireChange(){
        this.formData.medical_grounds = (
            !!this.formData.therapeutic_goal_questionnaire.question_1 ||
            !!this.formData.therapeutic_goal_questionnaire.question_2)
    }

    addItem(type: string) {
        this.formData[type].push({
            amount: null,
            reason: null,
            vue_id: StrRandom(),
        });
    }

    get appointmentTypes() {
        return this.data.appointmentTypes.map((item: Record<string, string>) => {
            return {
                ...item,
                name: this.$t(`CRM.Views.TemplateIndex.TreatmentReportTemplateIndex.AppointmentType.${item.name}`)
            }
        });
    }

    addInjectables() {
        const item: Injectable = {
            cc: null,
            expiration_date: null,
            lot_number: null,
            vue_id: StrRandom(),
        };

        this.formData['injectables'].push(item);
    }

    removeItem(type: string, index: number) {
        this.formData[type].splice(index, 1);
    }

    preSavePhotoAdded(value: number) {
        this.formData.pre_save_photos.push(value);
    }

    private getAvailableAttachments(id: number): Array<any> {
        return this.data.availableAttachments.filter((availableAttachment: any) =>
            !this.data.formData.attachments.some((attachment: any) => attachment.id === availableAttachment.id)
            || availableAttachment.id === id,
        );
    }

    private loadTemplate(): void {
        this.loadingTemplate = true;

        apiClient.get(`treatment-report-templates/by-category-and-type/${this.formData.treatment_category_id}/${this.formData.appointment_type_id}`)
            .then(r => {
                this.formData.report += r.data.translation.content[i18n.locale];

                store.dispatch('application/showNotification', 'TreatmentReportTemplateAdded');
            }).finally(() => {
            this.loadingTemplate = false;
        }).catch(() => {
            store.dispatch('application/showNotification', 'TreatmentReportTemplateNotMade');
        });
    }

    protected assignReportHistoryObject() {
        this.reportHistoryObject = {
            appointment: {
                datetime_from: this.data.appointment.datetime_from,
                datetime_till: this.data.appointment.datetime_till,
                id: this.data.appointment.id
            },
            practitioner: {
                name: this.data.practitioner.name,
                id: this.data.practitioner.id,
            },
            treatment: {
                categoryName: this.data.treatment.categoryName,
                name: this.data.treatment.name,
            },
            id: this.data.id,
        };
    }
}
