import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import ZvInsuranceModal from '@/components/ZvInsuranceModal/ZvInsuranceModal.vue';
import Popup from "@/components/popup/popup.vue";
import {default as PopupComponent} from "@/components/popup/popup";
import {Ref} from "vue-property-decorator";
import ZvInsuranceFormData from "@/Interfaces/ZvInsuranceFormData";
import apiClient from "@/apiClient";

@Component({
    components: {
        Popup,
        OverviewTable,
        ZvInsuranceModal
    },
})
export default class ZvInsuranceNamesIndex extends IndexView<ZvInsuranceFormData> {
    @Ref() deletePopup!: PopupComponent;
    protected endpoint = 'zv_insurances';
    protected endpointEdit = 'zv_insurances';

    public refresh() {
        this.setInsuranceData();
        this.$refs.overviewTable.refresh();
    }

    setInsuranceData(): void {
        apiClient.get('list/zv-insurance').then((response) => {
            sessionStorage.setItem('zv-insurance-list', JSON.stringify(response.data));
        });
    }

    deleteItem(data: any): void {
        this.deactivate(data.item).then(() => {
            this.$store.dispatch('application/showNotification', 'ZvInsuranceDeleted');
        });
    }

}
