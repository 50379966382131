import apiClient from '@/apiClient';
import { Commit } from 'vuex';
import dayjs from "dayjs";
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";

declare interface LanguageState {
    name: string;
    id: number;
}

export declare interface LocaleState {
    code: string;
    id: number;
    language: LanguageState;
}

const initialState = {
    locales: [] as Array<LocaleState>,
};

const mutations = {
    set(state: { locales: Array<LocaleState> }, payload: Array<LocaleState>) {
        state.locales = payload;

        if (!window.localStorage.getItem('locale')) {
            const userLocale = payload.find(locale => locale.code === navigator.language)?.code

            const locale = userLocale !== undefined ? userLocale : payload[0]['code'];

            i18n.locale = locale;
            vuetify.framework.lang.current = locale;
            dayjs.updateLocale(locale, {weekStart: 1});

            window.localStorage.setItem('locale', locale);
        }
    },
};

const actions = {
    fetchLocales(context: { commit: Commit; state: Array<LocaleState> }) {
        apiClient.get('locales').then((response) => {
            context.commit('set', response.data);
        });
    },
};

const getters = {
    getLocaleById: (state: { data: Array<LocaleState> }) => (id: number) => state.data.find((item: LocaleState) => item.id === id),
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters,
};
