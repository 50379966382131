import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import User from '@/Interfaces/User';
import Device from '@/Interfaces/Device';
import { objectToQueryString } from '@/utils/utils';
import apiClient from '@/apiClient';
import ScheduleCell from '@/components/Schedule/ScheduleCell/ScheduleCell.vue';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import dayjs, { Dayjs } from 'dayjs';

declare interface DeleteModalPropertiesInterface {
    recurrence_id: number;
    id?: number;
    name: string;
    date?: string;
}

@Component({
    name: 'Schedule',
    computed: {
        dayjs() {
            return dayjs
        }
    },
    components: {
        ScheduleCell,
        Popup,
    },
})
export default class Schedule extends Vue {
    @Prop(Number) readonly locationId!: number;
    @Prop(String) readonly entity!: string;
    @Prop(String) readonly translationKey!: string;
    @Prop(String) readonly name!: string;
    @Prop(Array) readonly weekdays!: Array<Dayjs>;
    @Prop(Number) readonly weekNumber!: number;
    @Ref() deletePopupSeries!: PopupComponent;
    @Ref() deletePopupOccurrence!: PopupComponent;

    public loading = false as boolean;
    public records = [] as Array<User | Device>;
    public deleteModalProperties = {} as DeleteModalPropertiesInterface;

    mounted(): void {
        this.fetchNewScheduleData();
    }

    @Watch('locationId')
    onLocationIdChange(): void {
        this.fetchNewScheduleData();
    }

    @Watch('weekNumber')
    fetchNewScheduleData(): void {
        this.loading = true;

        const startDate = this.weekdays[ 0 ].set('hours', 12);
        const endDate = this.weekdays[ this.weekdays.length - 1 ].set('hours', 12);

        const queryString = objectToQueryString({
            from: startDate.toISOString(),
            till: endDate.toISOString(),
        });

        apiClient.get(`recurrences/location/${this.locationId}/${this.entity}/?${queryString}`)
            .then((response) => this.records = response.data)
            .finally(() => this.loading = false);
    }

    get startDate() {
        return this.weekdays[ 0 ].set('hours', 12);
    }

    get popupIsOpen(): boolean {
        return this.deletePopupOccurrence.visible || this.deletePopupSeries.visible;
    }

    addButtonClicked(data: { record: User | Device; weekday: number; year: number }): void {
        const date = this.startDate.set('hours', 12).isoWeekday(data.weekday);

        const response = {
            record: data.record,
            date
        };

        this.$emit('addButtonClicked', response);
    }

    showDeleteConfirmationSeries(data: DeleteModalPropertiesInterface): void {
        this.deleteModalProperties = data;
        this.deletePopupSeries.toggle();
    }

    showDeleteConfirmationOccurrence(data: DeleteModalPropertiesInterface): void {
        this.deleteModalProperties = data;
        this.deletePopupOccurrence.toggle();
    }

    deleteSeries(): void {
        this.deletePopupSeries.toggle();

        const data = {
            date: this.deleteModalProperties.date,
        };

        apiClient.delete(`recurrences/${this.deleteModalProperties.recurrence_id}`, { data })
            .finally(() => this.fetchNewScheduleData());
    }

    deleteOccurrence(): void {
        this.deletePopupOccurrence.toggle();

        const data = {
            date: this.deleteModalProperties.date,
        };

        apiClient.delete(`recurrences/timetable/${this.deleteModalProperties.id}`, { data })
            .finally(() => this.fetchNewScheduleData());
    }

    editCell(payload: any) {
        this.$emit('editCell', payload);
    }

    getData(record: any, weekday: number) {
        return record.schedules.filter((i: any) => i.location_id === this.locationId).find((i: any) => i.weekday === weekday);
    }
}
