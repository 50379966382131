// Vue
import Component from 'vue-class-component';
// Project
import Vue from "vue";
import apiClient from "@/apiClient";
import {Watch} from "vue-property-decorator";
// @ts-ignore;
import AceEditor from 'vuejs-ace-editor';
import store from "@/store";


declare interface EmailCopyAvailable {
    id: number;
    slug: string;
}

@Component({
    components: {
        AceEditor
    }
})

export default class EmailTranslations extends Vue {
    public availableEmails: Array<EmailCopyAvailable> = [];
    public selectedEmailId: number = 0;
    public selectedEmail: Record<string, any> | null = null;
    public availableTranslations: Record<string, any> = {};
    private loading = {
        slugs: true,
        saving: false
    }

    public tab = 0;

    public customToolbar = [["bold", "italic", "underline"]];

    mounted(): void {
        this.fetchEmailCopySlugs();
    }

    @Watch('selectedEmailId')
    onSelectedEmailChange(): void {
        apiClient.get(`email-copies/${this.selectedEmailId}`).then((response) => {
            this.selectedEmail = response.data;
            Vue.set(this, 'availableTranslations', []);
            Object.keys(response.data.translation.copy).forEach(key => {
                this.availableTranslations[key] = {
                    rawHTML: false
                };
            })
        });
    }

    editorInit() {
        require('brace/ext/language_tools') //language extension prerequsite...
        require('brace/mode/html')
        require('brace/mode/javascript')    //language
        require('brace/mode/less')
        require('brace/theme/chrome')
        require('brace/snippets/javascript') //snippet
    }

    private fetchEmailCopySlugs(): void {
        this.loading.slugs = true;
        apiClient.get('email-copies').then((response) =>
            Vue.set(this, 'availableEmails', response.data)
        ).finally(() => this.loading.slugs = false);
    }

    getContentHighlightedVars(key: string): string {
        let content = this.selectedEmail?.translation.copy[key];

        content = content.replaceAll(/:\w+/g, (match: string) => {
            return `<code>${match}</code>`;
        });
        /*
                matches.forEach((match: string) => {
                    content  = content.replaceAll(match, "<code>"+match+"</code>")
                });*/

        return content;
    }

    save() {
        this.loading.saving = true;
        apiClient.put(`/email-copies/${this.selectedEmailId}`, this.selectedEmail).then(() => {
            // --
        }).catch(() => {
            // --
        }).finally(() => {
            store.dispatch('application/showNotification', 'AgreementTemplateSuccessfullyModified');
            this.loading.saving = false;
        });
    }
}
