import router from '@/router';
import { Commit, Dispatch } from 'vuex';

const state = {
	loginNeeded: false,
}

const mutations = {
	askForLogin(state: { loginNeeded: boolean }) {
		state.loginNeeded = true
		if(!router.currentRoute.meta?.preLogin) {
			router.push(`/login?target=${router.currentRoute.fullPath}`)
		}
	},

	passLoginCheck(state: { loginNeeded: boolean }) {
		state.loginNeeded = false
	}
}

const getters = {
	loginNeeded(state: { loginNeeded: boolean }): boolean {
		return state.loginNeeded;
	}

}

const actions = {
	askForLogin: (context: { commit: Commit }) => {
		context.commit('askForLogin')
	},

	passLoginCheck: (context: { commit: Commit; dispatch: Dispatch }) => {
		context.commit('passLoginCheck');
		context.dispatch('me/fetch', {}, {root: true});
		context.dispatch('application/showNotification', 'WatchOut', {root: true});
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
