import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import TreatmentComplicationForm from '@/views/DatabaseModels/Components/TreatmentComplications/Form/TreatmentComplicationForm.vue';

@Component({
    components: {
        OverviewTable,
        TreatmentComplicationForm,
    },
})
export default class TreatmentComplicationIndex extends IndexView<any> {
    protected endpoint = 'complications/dto';
}
