import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { namespace } from 'vuex-class';
import { LocaleState } from '@/store/modules/locales';

@Component
export default class LanguagePicker extends Vue {
    // Props
    @Prop({ default: true }) public readonly openBottom!: boolean;
    @Prop( {default: true}) public absoluteContainer!: boolean;

    // VueX
    @namespace('locales').State public locales: any;
    @namespace('locales').Action public fetchLocales: any;

    public languageMenuOpen: boolean = false;

    public getLocaleImage(locale: string): string {
        return `/countries/${locale}.svg`;
    }

    public get getContainerType(): string {
        if (this.absoluteContainer) {
            return 'language-picker-container-absolute';
        }
        else {
            return 'language-picker-container-flex';
        }
    }

    public get currentLocale() {
        return this.locales.find((locale: LocaleState) => locale.code === this.$i18n.locale)?.language.name;
    }

    public switchLocale(locale: string): void {
        if ( this.$i18n.locale !== locale ) {
            this.$root.$i18n.locale = locale;
            this.$vuetify.lang.current = locale;
            this.$i18n.locale = locale;
            dayjs.updateLocale(locale, {weekStart: 1});

            window.localStorage.setItem('locale', locale);
            this.fetchLocales();
        }
    }
}
