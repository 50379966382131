import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from './plugins/dayjs';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import PortalVue from 'portal-vue';
import {registerLocale} from 'i18n-iso-countries';
import '@/global.scss';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import TranslatableTextField from '@/components/TranslatableTextField/TranslatableTextField.vue';
import TranslatableTextarea from '@/components/TranslatableTextarea/TranslatableTextarea.vue';
import VueSignaturePad from 'vue-signature-pad';

declare global {
    interface Window {
        __env: {
            API_URL: string;
            DEFAULT_TITLE: string;
            GOOGLE_KEY: string;
            SLUG: string;
            DEFAULT_LOCALE?: string;
        };
    }
}

require('dayjs/locale/fr');
require('dayjs/locale/de');

Vue.component('translatable-text-field', TranslatableTextField);
Vue.component('translatable-textarea', TranslatableTextarea);

require('@/directives');

Vue.use(PerfectScrollbar);
Vue.use(dayjs);
Vue.use(PortalVue);
Vue.use(VueSignaturePad);

Vue.config.productionTip = false;

registerLocale(require('i18n-iso-countries/langs/nl.json'));
registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/fr.json'));
registerLocale(require('i18n-iso-countries/langs/de.json'));

const googleElement = document.createElement("script");

googleElement.src = `https://maps.googleapis.com/maps/api/js?key=${window.__env.GOOGLE_KEY}&libraries=places`;
googleElement.async = true;

document.head.appendChild(googleElement);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app');
