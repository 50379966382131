import { render, staticRenderFns } from "./TreatmentReportTemplateForm.vue?vue&type=template&id=088ce9f3&scoped=true&"
import script from "./TreatmentReportTemplateForm.ts?vue&type=script&lang=ts&"
export * from "./TreatmentReportTemplateForm.ts?vue&type=script&lang=ts&"
import style0 from "./TreatmentReportTemplateForm.scss?vue&type=style&index=0&id=088ce9f3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088ce9f3",
  null
  
)

export default component.exports