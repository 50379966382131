import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import TreatmentSideEffectsForm from '@/views/DatabaseModels/Components/TreatmentSideEffects/Form/TreatmentSideEffectsForm.vue';

@Component({
    components: {
        OverviewTable,
        TreatmentSideEffectsForm,
    },
})
export default class TreatmentSideEffectsIndex extends IndexView<any> {
    protected endpoint = 'side_effects/dto';
}
