import apiClient from '@/apiClient';
import { Commit } from 'vuex';
import { AxiosResponse } from 'axios';
import Location from '@/Interfaces/Location';
import { dynamicSort } from '@/utils/utils';

const initialState = {
    locations: [],
};

const mutations = {
    set(state: any, locations: Record<any, any>) {
        Object.assign(state, { locations });
        state.loading = false;
    },
    unset(state: any) {
        Object.assign(state, initialState);
        state.loading = true;
    },
};

const actions = {
    fetch(context: { commit: Commit; state: any }) {
        apiClient.get('list/locations').then((response: AxiosResponse) => {
            const mutated = response.data.map((item: Location) =>
                Object.assign(item, { placeAndName: [ item.place, item.name ].join(', ') }))
                .sort(dynamicSort('place'));

            context.commit('set', mutated);
        });
    },
};

const getters = {
    get(state: any){
        return state;
    }
}

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
