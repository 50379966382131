import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import popup from '@/components/popup/popup.vue';
import EmployeeFormData from '@/Interfaces/EmployeeFormData';
import {namespace} from 'vuex-class';
import apiClient from '@/apiClient';
import Brand from '@/Interfaces/Brand';
import Location from '@/Interfaces/Location';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import Role from '@/Interfaces/Role';
import EmployeeMFAConfirmModal from '@/views/EmployeeIndex/EmployeeMFAConfirmModal/EmployeeMFAConfirmModal.vue';
import Vue from 'vue';
import {Watch} from 'vue-property-decorator';
import Qualification from "@/Interfaces/Qualification";

@Component({
    components: {EmployeeMFAConfirmModal, DeactivateToggle, popup},
})

export default class EmployeeForm extends PopupForm<EmployeeFormData> {
    @namespace('me').State id!: any;
    @namespace('me').State permissions: any;

    protected modelName = 'Employee';
    protected endpoint = 'employees';

    public locations: Array<Location> = [];
    public brands: Array<Brand> = [];
    public roles: Array<Role> = [];
    public qualifications: Array<Qualification> = [];

    public weekDays = [...Array(7).keys()].map(day => {
        return this.$dayjs().startOf('week').add(day, 'day').format('dd');
    });

    public dataLoading = {
        brands: false,
        locations: false,
        roles: false,
        qualifications: false
    };

    @Watch('formData.locationIds')
    private locationIdsChanged() {
        this.getQualifications();
    }

    @Watch('formData.roleIds')
    private roleChanged() {
        if (this.formData.id) return;

        this.salonOwner ?
            Vue.set(this.formData, 'mfa_active', false) :
            Vue.set(this.formData, 'mfa_active', true);
    }

    public afterToggle(): void {
        this.formData.brandIds = this.formData.brandIds || this.brands.map(b => b.id as number);

        this.getBrands();
        this.getLocations();
        this.getRoles();

        if (!this.formData.id) {
            Vue.set(this.formData, 'mfa_active', true);
        }
    }

    public get salonOwner(): boolean {
        return !!this.roles.find(role => this.formData.roleIds?.find(id => id === role.id) && role.slug === 'salon-owner');
    }

    public get practitioner(): boolean {
        return !!this.roles.find(role => this.formData.roleIds?.find(id => id === role.id) && role.practitioner);
    }

    public get self(): boolean {
        return this.formData.id === this.id;
    }

    /**
     * Get all brands (laser devices)
     */
    private getBrands(): void {
        this.brands = [];

        apiClient.get('brands')
            .then(response => this.brands = response.data)
            .finally(() => this.dataLoading.brands = false);

        this.dataLoading.brands = true;
    }

    /**
     * Get all locations
     */
    private getLocations(): void {
        this.locations = [];

        apiClient.get('locations/raw')
            .then(response => this.locations = response.data)
            .finally(() => this.dataLoading.locations = false);

        this.dataLoading.locations = true;
    }

    /**
     * Get all roles
     */
    private getRoles(): void {
        this.roles = [];

        apiClient.get('roles')
            .then(response => this.roles = response.data)
            .finally(() => this.dataLoading.roles = false);

        this.dataLoading.roles = true;
    }

    private getQualifications(): void {
        this.qualifications = [];
        this.dataLoading.qualifications = true;

        apiClient.get('qualifications/countries/list', {
            params: {
                ids: this.formData.locationIds
            }
        })
            .then(response => {
                this.qualifications = response.data
                const arrayOfOnlyIds = response.data.map((entry: any) => entry.id);

                this.formData.qualificationIds = this.formData.qualificationIds.filter((item: number) => arrayOfOnlyIds.includes(item));
            })
            .finally(() => this.dataLoading.qualifications = false);
    }
}
