import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import Product from '@/Interfaces/Product';
import ProductForm from '@/views/ProductIndex/Forms/ProductForm.vue';
import IndexView from '@/partials/IndexView';
import { Watch } from 'vue-property-decorator';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';
import PriceLevelForm from "@/views/PriceLevelIndex/Forms/PriceLevelForm.vue";

@Component({
    components: {
        OverviewTable,
        ProductForm,
        SupportedCountries,
        PriceLevelForm
    },
})
export default class PriceLevelIndex extends IndexView<Product> {
    protected endpoint: string | null = null;
    private selectedSupportedCountryId: number | null = null;

    protected endpointEdit = 'price-levels';

    @Watch('selectedSupportedCountryId')
    onselectedSupportedCountryIdChange() {
        if ( this.selectedSupportedCountryId ) {
            this.endpoint = `price-levels/country/${this.selectedSupportedCountryId}`;
        }
    }
}
