import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import { Ref, Watch } from 'vue-property-decorator';
import TranslatableTextarea from '@/components/TranslatableTextarea/TranslatableTextarea.vue';
import { namespace } from 'vuex-class';
import Language from '@/Interfaces/Language';
import apiClient from '@/apiClient';
import AgreementTemplateFormData from '@/Interfaces/AgreementTemplateFormData';
import TranslatableTextField from '@/components/TranslatableTextField/TranslatableTextField.vue';

@Component({
    components: {
        popup,
        DeactivateToggle,
        Wysiwyg,
        TranslatableTextarea,
        TranslatableTextField,
    },
})
export default class AgreementTemplateForm extends PopupForm<AgreementTemplateFormData> {
    @namespace('mru').State('primarySupportedCountryId') primarySupportedCountryId!: any;
    @namespace('me').State id: any;
    @Ref() error!: HTMLElement;
    protected modelName = 'AgreementTemplate';
    protected endpoint = '';
    protected languages = [] as Array<Language>;

    afterToggle(): void {
        this.getLanguages();
    }

    @Watch('primarySupportedCountryId')
    private changedSupportedCountry(): void {
        this.endpoint = !this.formData?.id ? 'template-agreements/country/' + this.primarySupportedCountryId : 'template-agreements';
    }

    private getLanguages() {
        apiClient.get(`supported-countries/dto/${this.primarySupportedCountryId}`).then((response: any) => {
            this.languages = response.data.locales.map((locale: any) => {
                return {
                    id: locale.id,
                    name: locale.language.name,
                    code: locale.code,
                };
            });
        }).finally(() => {
            if ( !this.formData.id ) {
                this.setContentLanguages();
            }
        });
    }

    private setContentArray(): void {
        this.formData.translation = {};
        Object.assign(this.formData.translation, { content: {} });
    }

    private setContentLanguages(): void {
        this.setContentArray();

        this.languages.forEach((language: Language) => {
            Object.assign(this.formData.translation[ 'content' ], { [ language.code ]: '' });
        });
    }

    @Watch('errorMessage')
    private onErrorMessage(): void {
        if ( this.errorMessage === undefined ) return;

        setTimeout(() => {
            const scrollPopup = this.$refs?.popup?.$refs?.scrollPopup as HTMLElement;
            scrollPopup.scrollTo({ left: 0, top: this.$refs.error.offsetTop, behavior: 'smooth' });
        }, 250);
    }

    updated(): void {
        if ( !this.formData.id ) {
            this.endpoint = 'template-agreements/country/' + this.primarySupportedCountryId;
        } else {
            this.endpoint = 'template-agreements';
        }
    }

    mounted(): void {
        if ( !this.formData.id ) {
            this.setContentArray();
        }
    }
}
