import Component from "vue-class-component";
import SupportedCountries from "@/components/Autocompletes/SupportedCountries/SupportedCountries.vue";
import {Watch} from "vue-property-decorator";
import IndexView from "@/partials/IndexView";
import OverviewTable from "@/components/OverviewTable/OverviewTable.vue";
import QualificationForm from "@/views/QualificationIndex/Forms/QualificationForm.vue";
import Popup from "@/components/popup/popup.vue";
import apiClient from "@/apiClient";
import Model from "@/Interfaces/Model";
import Loader from "@/components/Loader/Loader.vue";

@Component({
    name: 'QualificationIndex',
    components: {Loader, Popup, QualificationForm, OverviewTable, SupportedCountries},
})
export default class QualificationIndex extends IndexView<any> {
    protected endpoint: string | null = null;
    protected endpointEdit = 'qualifications';
    private selectedSupportedCountryId: number | null = null;
    public deleteInProgress = false;
    private deletePopupConfirmEnabled = false;
    private deletePopupText = this.$t('CRM.Views.QualificationIndex.Popup.Delete.Body', {
        treatments: 0,
        users: 0
    })

    @Watch('selectedSupportedCountryId')
    onSelectedSupportedCountryIdChange(): void {
        if (this.selectedSupportedCountryId) {
            this.endpoint = `qualifications/country/${this.selectedSupportedCountryId}`;
        }
    }

    deleteItem(data: any): void {
        this.deactivate(data.item).then(() => {
            this.$store.dispatch('application/showNotification', 'ZvInsuranceDeleted');
        });
    }

    protected afterDeletePopup(data: Model): void {
        this.deletePopupConfirmEnabled = false;
        apiClient.get(`qualifications/${data.item.id}/connection-count`).then((response) => {
            this.deletePopupText = this.$t('CRM.Views.QualificationIndex.Popup.Delete.Body', {
                treatments: response.data.treatments,
                users: response.data.users
            });
        }).finally(() => {
            this.deletePopupConfirmEnabled = true;
        });
    }
}