import { render, staticRenderFns } from "./TreatmentSideEffectsIndex.vue?vue&type=template&id=4698dd76&"
import script from "./TreatmentSideEffectsIndex.ts?vue&type=script&lang=ts&"
export * from "./TreatmentSideEffectsIndex.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports