import Component from 'vue-class-component';
import Vue from 'vue';
import UserMenu from '@/components/UserMenu/UserMenu.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import { namespace } from 'vuex-class';
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue';

@Component({
    components: { UserMenu, Navigation, LanguagePicker },
})
export default class App extends Vue {
    @namespace('application').State notifications: any;
    @namespace('authentication').State loginNeeded: any;

    @namespace('me').State detailsNeeded: any;
    @namespace('me').Action fetch: any;

    @namespace('country').Action fetchCountries: any;
    @namespace('locales').Action fetchLocales: any;

    mounted() {
        this.fetch();
        this.fetchCountries();
        this.fetchLocales();
        this.applyViewport();
        this.appHeight();

        window.addEventListener('resize', () => {
            this.applyViewport();
            this.appHeight();
        });
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

    applyViewport() {
        if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {

            const ww = window.screen.width;
            const mw = 1280; // min width of site
            const ratio = (ww / mw) * 0.75; //calculate ratio
            const viewport_meta_tag = document.getElementById('viewport');
            if ( viewport_meta_tag ) {
                if ( ww < mw ) { //smaller than minimum size
                    viewport_meta_tag.setAttribute('content', 'initial-scale=' + ratio + ', maximum-scale=' + ratio + ', minimum-scale=' + ratio + ', user-scalable=yes, width=' + mw);
                } else { //regular size
                    viewport_meta_tag.setAttribute('content', 'initial-scale=1.0, maximum-scale=1, minimum-scale=1.0, user-scalable=yes, width=' + ww);
                }
            }
        }
    }
}
