import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import TreatmentReportTemplateFormData from '@/Interfaces/TreatmentReportTemplateFormData';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import {Ref, Watch} from 'vue-property-decorator';
import TranslatableTextarea from '@/components/TranslatableTextarea/TranslatableTextarea.vue';
import {namespace} from 'vuex-class';
import Language from '@/Interfaces/Language';
import apiClient from '@/apiClient';

@Component({
    components: {
        popup,
        DeactivateToggle,
        Wysiwyg,
        TranslatableTextarea,
    },
})
export default class TreatmentReportTemplateForm extends PopupForm<TreatmentReportTemplateFormData> {
    @namespace('mru').State('primarySupportedCountryId') primarySupportedCountryId!: any;
    @namespace('me').State id: any;

    @Ref() error!: HTMLElement;

    protected modelName = 'TreatmentReportTemplate';
    protected endpoint = '';
    public categories = [] as Array<string>;
    public hasNoTemplateTreatmentCategories = [] as Array<string>;
    public appointmentTypes = [] as Array<string>;
    protected languages = [] as Array<Language>;

    public dataLoading = {
        categories: false,
        appointmentTypes: false,
    };

    afterToggle(): void {
        this.getCategories();
        this.getAppointmentTypes();
        this.getLanguages();
    }

    @Watch('primarySupportedCountryId')
    private changedSupportedCountry(): void {
        this.endpoint = !this.formData?.id ? 'treatment-report-templates/' + this.primarySupportedCountryId : 'treatment-report-templates';
    }

    private getLanguages() {
        apiClient.get(`supported-countries/dto/${this.primarySupportedCountryId}`).then((response: any) => {
            this.languages = response.data.locales.map((locale: any) => {
                return {
                    id: locale.id,
                    name: locale.language.name,
                    code: locale.code,
                };
            });
        }).finally(() => {
            if ( !this.formData.id ) {
                this.setContentLanguages();
            }
        });
    }

    private setContentArray(): void {
        this.formData.translation = {};
        Object.assign(this.formData.translation, { content: {} });
    }

    private setContentLanguages(): void {
        this.setContentArray();

        this.languages.forEach((language: Language) => {
            Object.assign(this.formData.translation[ 'content' ], { [ language.code ]: '' });
        });
    }

    private getAppointmentTypes() {
        this.appointmentTypes = [];
        this.dataLoading.appointmentTypes = true;

        apiClient.get('list/appointment-types').then((response: any) => {
            this.appointmentTypes = response.data.map((item: Record<string, string>) => {
                return {
                    ...item,
                    name: this.$t(`CRM.Views.TemplateIndex.TreatmentReportTemplateIndex.AppointmentType.${item.name}`)
                }
            });
        }).finally(() => {
            this.dataLoading.appointmentTypes = false;
        });
    }

    private getCategories() {
        this.categories = [];
        this.dataLoading.categories = true;

        apiClient.get('list/treatment-categories').then((response: any) => {
            this.categories = response.data;
        }).finally(() => {
            this.dataLoading.categories = false;
        });
    }

    private getHasNoTemplateTreatmentCategories() {
        this.hasNoTemplateTreatmentCategories = [];
        this.dataLoading.categories = true;

        apiClient.get(`treatment-categories/has-no-template/${this.primarySupportedCountryId}/${this.formData.appointment_type_id}`)
            .then((response: any) => {
                this.categories = response.data;

            }).finally(() => {
            this.dataLoading.categories = false;
        });
    }

    @Watch('formData.appointment_type_id')
    private onAppointmentTypeChange(value: number, old: number): void {
        if ( old === undefined && this.formData.id ) return;

        if ( !this.dataLoading.categories ) {
            this.formData.treatmentCategoryIds = [];
        }

        if ( this.formData.appointment_type_id ) {
            this.getHasNoTemplateTreatmentCategories();
        }
    }

    @Watch('errorMessage')
    private onErrorMessage(): void {
        if ( this.errorMessage === undefined ) return;

        setTimeout(() => {
            const scrollPopup = this.$refs?.popup?.$refs?.scrollPopup as HTMLElement;
            scrollPopup.scrollTo({ left: 0, top: this.$refs.error.offsetTop, behavior: 'smooth' });
        }, 250);
    }

    updated(): void {
        if ( !this.formData.id ) {
            this.endpoint = 'treatment-report-templates/' + this.primarySupportedCountryId;
        } else {
            this.endpoint = 'treatment-report-templates';
        }
    }

    mounted(): void {
        if ( !this.formData.id ) {
            this.setContentArray();
        }
    }
}
