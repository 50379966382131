import { render, staticRenderFns } from "./QualificationIndex.vue?vue&type=template&id=3ccd88ad&scoped=true&"
import script from "./QualificationIndex.ts?vue&type=script&lang=ts&"
export * from "./QualificationIndex.ts?vue&type=script&lang=ts&"
import style0 from "./QualificationIndex.scss?vue&type=style&index=0&id=3ccd88ad&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ccd88ad",
  null
  
)

export default component.exports