import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import ZvInsuranceFormData from "@/Interfaces/ZvInsuranceFormData";

@Component({
    name: 'ZvInsuranceModal',
    components: {
        Modal,
    }
})
export default class ZvInsuranceModal extends PopupForm<ZvInsuranceFormData> {
    protected endpoint = "zv_insurances";
    protected modelName: string = "ZvInsurance";

    private get canSave(): boolean|undefined {
        return !!this.formData.name_short && !!this.formData.name_long;
    }

}
