import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import TreatmentReportTemplate from '@/Interfaces/TreatmentReportTemplate';
import AgreementTemplateForm from '@/views/TemplateIndex/Components/AgreementTemplateIndex/Forms/AgreementTemplateForm.vue';
import {Prop, Ref} from 'vue-property-decorator';
import Popup from "@/components/popup/popup.vue";
import {default as PopupComponent} from "@/components/popup/popup";

declare interface Item {
    actions?: Array<string>;
    id: number;
    name: string;
    updated_at: string;
}

@Component({
    components: {
        Popup,
        OverviewTable,
        AgreementTemplateForm,
    },
})

export default class AgreementTemplateIndex extends IndexView<TreatmentReportTemplate> {
    @Ref() deletePopup!: PopupComponent;
    @Prop({default: null}) endpoint!: string | null;
    @Prop({default: null}) endpointEdit!: string | null;
    protected selectedSupportedCountryId: number | null = null;

    protected refresh() {
        this.$refs.overviewTable.refresh();
    }

    deleteItem(data: any): void {
        this.deactivate(data.item).then(() => {
            this.$store.dispatch('application/showNotification', 'TemplateAgreementDeleted');
        });
    }
}
