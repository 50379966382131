import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import TreatmentFormData from '@/Interfaces/TreatmentFormData';
import apiClient from '@/apiClient';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import PriceField from '@/components/PriceField/PriceField.vue';
import LedgerNumberAutocomplete from '@/components/Autocompletes/LedgerNumberAutocomplete/LedgerNumberAutocomplete.vue';
import TreatmentDurationField from '@/components/TreatmentDurationField/TreatmentDurationField.vue';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';
import ImageUploader from 'vue-image-upload-resize';
import { Prop, Watch } from 'vue-property-decorator';
import VatSelect from '@/components/VatSelect/VatSelect.vue';
import ModelPicker from "@/views/CalendarIndex/Forms/Components/ModelPicker/ModelPicker.vue";
import PriceLevelPriceInput from "@/components/PriceLevelPicker/PriceLevelPriceInput.vue";
import TreatmentCategory from "@/Interfaces/TreatmentCategory";

@Component({
    components: {
        PriceLevelPriceInput,
        ModelPicker,
        popup,
        DeactivateToggle,
        PriceField,
        LedgerNumberAutocomplete,
        TreatmentDurationField,
        SupportedCountries,
        ImageUploader,
        VatSelect
    },
})

export default class TreatmentForm extends PopupForm<TreatmentFormData> {
    @Prop({ default: 0 }) supportedCountryId!: number;
    protected modelName = 'Treatment';
    protected endpoint = 'treatments';
    public categories = [] as Array<any>;
    public roles = [] as Array<string>;
    public qualifications = [] as Array<string>;

    public dataLoading = {
        categories: false,
        roles: false,
        qualifications: false,
        supportedCountries: true,
    };

    afterToggle(): void {
        this.getCategories();
        this.getRoles();
        this.getQualifications();

        this.formData.image = this.formData.image || null;
    }

    private getCategories() {
        this.categories = [];
        this.dataLoading.categories = true;

        apiClient.get('list/treatment-categories').then((response) => {
            this.categories = response.data;
        }).finally(() => {
            this.dataLoading.categories = false;
        });
    }

    private getQualifications(){
        this.qualifications = [];
        this.dataLoading.qualifications = true;

        apiClient.get(`qualifications/country/${this.supportedCountryId}/list`).then((response) => {
            this.qualifications = response.data;
        }).finally(() => {
            this.dataLoading.qualifications = false;
        });
    }
    private getRoles() {
        this.roles = [];
        this.dataLoading.roles = true;

        apiClient.get('roles/practitioner-roles').then((response) => {
            this.roles = response.data;
        }).finally(() => {
            this.dataLoading.roles = false;
        });
    }

    private setImage(file: string) {
        this.formData.image = file;
    }

    @Watch('formData.can_pay_online')
    onCanPayOnlineChange() {
        this.formData.advance_payment_method = this.formData.can_pay_online ? 'down_payment' : null;
    }

    public isActiesCategory() {
        const treatmentCategoryId = this.formData.treatment_category_id;
        const category = this.categories.find((item: TreatmentCategory) => item.id === treatmentCategoryId);
        return category ? category.slug === 'acties' : false;
    }
}
