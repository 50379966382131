// Vue
import Component from 'vue-class-component';
// Project
import apiClient from '@/apiClient';
import AppointmentFormData from '@/Interfaces/AppointmentFormData';
import ClientPicker from '@/components/ClientPicker/ClientPicker.vue';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import DevicePicker from '@/components/DevicePicker/DevicePicker.vue';
import LocationPicker from '@/components/LocationPicker/LocationPicker.vue';
import Popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import TreatmentPicker from '@/components/TreatmentPicker/TreatmentPicker.vue';
import Vue from 'vue';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';

@Component({
    components: {
        ClientPicker,
        DeactivateToggle,
        DevicePicker,
        LocationPicker,
        Popup,
        TreatmentPicker,
        SupportedCountries,
    },
})

export default class AppointmentForm extends PopupForm<AppointmentFormData> {
    protected modelName = 'Appointment';
    protected endpoint = 'appointments';

    public locations: Array<any> = [];
    public rooms: Array<any> = [];
    public users: Array<any> = [];


    public get datetime_from(): string {
        return this.getDatetime(this.formData.datetime_from ?? new Date().toString());
    }

    public set datetime_from(value: string) {
        Vue.set(this.formData, 'datetime_from', new Date(value).toISOString());
    }

    public get datetime_till(): string {
        return this.getDatetime(this.formData.datetime_till ?? new Date().toString());
    }

    public set datetime_till(value: string) {
        Vue.set(this.formData, 'datetime_till', new Date(value).toISOString());
    }

    public dataLoading = {
        locations: false,
        rooms: false,
        users: false,
    };

    public afterToggle(): void {
        if ( !this.formData.datetime_from ) {
            Vue.set(this.formData, 'datetime_from', new Date().toISOString());
        }

        if ( !this.formData.datetime_till ) {
            Vue.set(this.formData, 'datetime_till', new Date().toISOString());
        }

        this.getLocations();
        this.getRooms();
        this.getUsers();
    }

    public get locationRooms(): Array<any> {
        return this.rooms.filter(room => room.location_id === this.formData.location_id);
    }

    private getLocations(): void {
        this.locations = [];

        apiClient.get('list/locations')
            .then(response => this.locations = response.data)
            .finally(() => this.dataLoading.locations = false);

        this.dataLoading.locations = true;
    }

    private getRooms(): void {
        this.rooms = [];

        apiClient.get('list/rooms')
            .then(response => this.rooms = response.data)
            .finally(() => this.dataLoading.rooms = false);

        this.dataLoading.rooms = true;
    }

    private getUsers(): void {
        this.users = [];

        apiClient.get('list/employees')
            .then(response => this.users = response.data.body)
            .finally(() => this.dataLoading.users = false);

        this.dataLoading.users = true;
    }

    private getDatetime(datetime: string): string {
        const date = new Date(datetime);

        return [
            [
                date.getFullYear().toString().padStart(4, '0'),
                (date.getMonth() + 1).toString().padStart(2, '0'),
                date.getDate().toString().padStart(2, '0'),
            ].join('-'),
            [
                date.getHours().toString().padStart(2, '0'),
                date.getMinutes().toString().padStart(2, '0'),
                date.getSeconds().toString().padStart(2, '0'),
            ].join(':'),
        ].join('T');
    }

    get appointmentIsASingleDay(): boolean {
        const start = this.$dayjs(this.formData.datetime_from).format('DD-MM-YYYY');
        const end = this.$dayjs(this.formData.datetime_till).format('DD-MM-YYYY');

        return start === end;
    }

    get allDatesValid(): boolean {
        return this.dateFromIsEarlierThanDateTill
            && this.dateRangeIsWithinThreeYearLimit
            && this.dateFromIsLessThanThreeMonthsAgo;
    }

    get dateFromIsEarlierThanDateTill(): boolean {
        this.$dayjs.extend(isSameOrBefore);
        const start = this.$dayjs(this.formData.datetime_from);
        const end = this.$dayjs(this.formData.datetime_till);

        return start.isSameOrBefore(end);
    }

    get dateRangeIsWithinThreeYearLimit(): boolean {
        const start = this.$dayjs(this.formData.datetime_from);
        const end = this.$dayjs(this.formData.datetime_till);

        const threeYearsLater = start.add(3, 'year');

        return !end.isAfter(threeYearsLater);
    }

    get dateFromIsLessThanThreeMonthsAgo(): boolean {
        const start = this.$dayjs(this.formData.datetime_from);
        const now = this.$dayjs();

        const threeMonthsAgo = now.subtract(3, 'month');

        return !start.isBefore(threeMonthsAgo);
    }
}
