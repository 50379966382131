export declare interface ComparePhotoInterface {
    id: number;
}

declare interface ReportHistoryClientEmailInterface {
    email: string;
}

declare interface ReportHistoryClientAppointmentInterface {
    id: number;
}

export declare interface ReportHistoryInterface {
    appointment: {
        datetime_from: string;
        datetime_till: string;
        id?: number;
    };
    practitioner: {
        name: string;
        id: number;
    };
    treatment: {
        categoryName: string;
        name: string;
    };
    photos?: [
        {
            id: number;
            name: string;
            thumbnailId: number;
        }
    ];
    id: number | undefined;
}

export declare interface ReportHistoryState {
    reportHistory: Array<ReportHistoryInterface>;
    compareImageLeft: ComparePhotoInterface | null;
    compareImageRight: ComparePhotoInterface | null;
    comparingClientEmail: ReportHistoryClientEmailInterface | null;
    comparingClientAppointment: ReportHistoryClientAppointmentInterface | null;
}

const initialState = {
    reportHistory: [],
    compareImageLeft: null,
    compareImageRight: null,
    comparingClientEmail: null,
    comparingClientAppointment: null,
} as ReportHistoryState;

const mutations = {
    add(state: ReportHistoryState, reportHistory: Record<any, any>) {
        state.reportHistory.push(...reportHistory.data);
    },
    set(state: { reportHistory: Array<ReportHistoryState> }, payload: Record<any, any>) {
        state.reportHistory = payload.data;
    },
    setCompareClient(state: { comparingClientEmail: ReportHistoryClientEmailInterface }, payload: Record<any, any>) {
        state.comparingClientEmail = payload.data;
    },
    setCompareAppointment(state: { comparingClientAppointment: ReportHistoryClientAppointmentInterface }, payload: Record<any, any>) {
        state.comparingClientAppointment = payload.data;
    },
    setLeftCompareImage(state: { compareImageLeft: ComparePhotoInterface }, payload: Record<any, any>) {
        state.compareImageLeft = payload.data;
    },
    setRightCompareImage(state: { compareImageRight: ComparePhotoInterface }, payload: Record<any, any>) {
        state.compareImageRight = payload.data;
    },
};

const actions = {};

const getters = {
    state(state: { data: any }) {
        return state;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters,
};
