import apiClient from '@/apiClient';
import { Commit } from 'vuex';
import CacheService from '@/services/CacheService';

declare interface CountryState {
    code: string;
    id: number;
    name: string;
}

const initialState = {
    data: [] as Array<CountryState>,
};

const mutations = {
    set(state: { data: Array<CountryState> }, payload: Array<CountryState>) {
        state.data = payload;
    },
};

const actions = {
    fetchCountries(context: { commit: Commit; state: Array<CountryState> }) {
        const cacheKey = 'countriesList';

        if ( CacheService.check(cacheKey) ) {
            context.commit('set', CacheService.get(cacheKey));
        } else {
            apiClient.get('countries').then((response) => {
                context.commit('set', response.data);
                CacheService.set(cacheKey, response.data);
            });
        }
    },
};

const getters = {
    getCountryById: (state: { data: Array<CountryState> }) => (id: number) => state.data.find((item: CountryState) => item.id === id),
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters,
};
