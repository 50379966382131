import axios from 'axios';
import store from '@/store';
import i18n from '@/i18n';

const apiClient = axios.create({
    baseURL: window.__env.API_URL,
    withCredentials: true,
});

apiClient.interceptors.request.use(request => {
    request.headers[ 'X-Timezone' ] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    request.headers[ 'x-locale' ] = i18n.locale + '_NL'; // TODO: moet nog aangepast worden, is nu hardcoded op NL

    return request;
});

// Handle 403 errors by asking the user to login.
// After login, the `.then` in the function that made the request will be executed.
apiClient.interceptors.response.use(response => {
    return response;
}, (error) => {

    if ( error.response?.status === 401 && error.response?.config.url !== '/login' ) {
        store.dispatch('authentication/askForLogin');

        // Wait for login and retry
        return new Promise(resolve => {
            const retryer = setInterval(() => {
                if ( store.getters[ 'authentication/loginNeeded' ] === false ) {
                    apiClient(error.config.url, error.config).then((response) => {
                        clearInterval(retryer);
                        resolve(response);
                    });
                }
            }, 1000);
        });
    }

    if ( !error.response?.status && !axios.isCancel(error) ) {
        store.dispatch('application/showNotification', 'SomethingWentWrong');
    }

    return Promise.reject(error);
});

export default apiClient;
