import dayjs from 'dayjs';

declare interface CacheObject {
    data: any;
    expires_at: number;
}

export default class CacheService {
    /**
     * Place item in cache
     *
     * @param key
     * @param payload
     * @param ttl hours
     */
    static set(key: string, payload: any, ttl: number = 8) {
        const item: CacheObject = {
            data: payload,
            expires_at: dayjs().add(ttl, 'hours').unix(),
        };

        localStorage.setItem(key, JSON.stringify(item));
    }

    /**
     * Get an item from cache
     *
     * @param key
     */
    static get(key: string): any {
        if ( CacheService.check(key) ) {
            const item = JSON.parse(localStorage.getItem(key) as string) as CacheObject;

            return item.data;
        }

        return null;
    }

    /**
     * Check if item exists & is not expired
     *
     * @param key
     */
    static check(key: string): boolean {
        const tmp = localStorage.getItem(key);
        if ( !tmp ) {
            return false;
        }

        const item = JSON.parse(tmp as string) as CacheObject;
        if ( item.expires_at <= dayjs().unix() ) {
            localStorage.removeItem(key);

            return false;
        }

        return true;
    }

    /**
     * Removes item from cache
     *
     * @param key
     */
    static delete(key: string): void {
        localStorage.removeItem(key);
    }
}
