import apiClient from '@/apiClient';
import MutationType from '@/Interfaces/MutationType';
import { Commit } from 'vuex';

declare interface MutationTypeState {
    data: Array<MutationType>;
}

const initialState = {
    data: [] as Array<MutationType>,
} as MutationTypeState;

const mutations = {
    set(state: MutationTypeState, payload: Array<MutationType>) {
        state.data = payload;
    },
};

const actions = {
    fetchMutationTypes(context: { commit: Commit; state: MutationTypeState }) {
        if ( context.state.data.length === 0 ) {
            apiClient.get('mutation_type').then((response) => {
                context.commit('set', response.data);
            });
        }
    },
};

const getters = {
    getMutationById: (state: { data: Array<MutationType> }) => (id: number) => state.data.find((item: MutationType) => item.id === id),
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters,
};
