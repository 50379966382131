import Vue from 'vue';
import Cleave from 'cleave.js';

export interface CleaveElement extends HTMLElement {
	cleave?: any;
	value?: any;
}

/**
 * https://nosir.github.io/cleave.js/
 */
Vue.directive('text-field-mask', {
	bind: (el: CleaveElement, binding) => {
		el = el.getElementsByTagName('input')[0] as CleaveElement;
		el.cleave = new Cleave(el, binding.value || {})

		el.addEventListener('change', () => {
			el.value = el.cleave.properties.result
		});

		el.addEventListener('blur', () => {
			el.value = el.value ? el.cleave.properties.result : null;
		});

		el.addEventListener('focus', () => {
			el.value = el.value ? el.cleave.properties.result : null;
		});

	}
})
