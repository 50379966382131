import apiClient from '@/apiClient';
import { Commit } from 'vuex';
import PaymentMethod from '@/Interfaces/PaymentMethod';

declare interface PaymentMethodState {
    data: Array<PaymentMethod>;
}

const initialState = {
    data: [] as Array<PaymentMethod>,
} as PaymentMethodState;

const mutations = {
    set(state: PaymentMethodState, payload: Array<PaymentMethod>) {
        state.data = payload;
    },
};

const actions = {
    fetchPaymentMethods(context: { commit: Commit; state: PaymentMethodState }) {
        if ( context.state.data.length === 0 ) {
            apiClient.get('payment_method').then((response) => {
                context.commit('set', response.data);
            });
        }
    },
};

const getters = {
    getPaymentMethodsForItems: (state: { data: Array<PaymentMethod> }) => (items: Array<string>) =>
        state.data.filter((obj: PaymentMethod) => items.every(type => !!obj[ type ])),

    getPaymentMethodBySlug: (state: { data: Array<PaymentMethod> }) => (slug: string) => state.data.find((item: PaymentMethod) => item.slug === slug),
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters,
};
